const videos = document.querySelectorAll(".gallery__play");

if (videos) {
  videos.forEach((video) => {
    video.addEventListener("click", () => {
      const targetVideo = document.getElementById(video.dataset.video);
      targetVideo.play();
      targetVideo.controls = true;
      video.style.display = "none";
    });
  });
}
