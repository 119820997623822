const slider = document.querySelectorAll(".slider");

slider?.forEach((slider) => {
  const sliderWrapper = slider.querySelector(".slider__wrapper");
  const sliderContainer = slider.querySelector(".slider__container");
  const sliderNav = slider.querySelector(".slider__nav");
  let slidePrev;
  let slideNext;

  const itemWidth =
    sliderContainer?.querySelector(".slider__item")?.offsetWidth;

  if (sliderNav) {
    slidePrev = sliderNav.querySelector(".slider__prev");
    slideNext = sliderNav.querySelector(".slider__next");
  }

  const watchNavState = () => {
    if (sliderContainer.scrollLeft === 0) {
      slidePrev.classList.remove("active");
    } else {
      slidePrev.classList.add("active");
    }
    if (
      sliderContainer.scrollLeft + sliderWrapper.offsetWidth >=
      sliderContainer.scrollWidth
    ) {
      slideNext.classList.remove("active");
    } else {
      slideNext.classList.add("active");
    }
  };

  watchNavState();

  if (slideNext) {
    slideNext.addEventListener("click", () => {
      watchNavState();
      if (
        sliderContainer.scrollLeft + sliderWrapper.offsetWidth <
        sliderContainer.scrollWidth
      ) {
        sliderContainer.scrollLeft += itemWidth * 1.5;
      } else {
      }
    });
  }
  if (slidePrev) {
    slidePrev.addEventListener("click", () => {
      watchNavState();
      if (
        sliderContainer.scrollLeft - sliderWrapper.offsetWidth <
        sliderContainer.scrollWidth
      ) {
        sliderContainer.scrollLeft -= itemWidth * 1.5;
      } else {
      }
    });
  }
});

//HTML STRUCTURE

/* <div class="slider">
  <div class="slider__wrapper">
    <div class="slider__container">
      <div class="slider__item">
        ...ITEM
        </div>
      <div class="slider__item">
        ...ITEM
        </div>
      <div class="slider__item">
        ...ITEM
        </div>
    </div>
  </div>
  <div class="slider__nav">
    <div class="slider__prev">
      <div class="icon-arrow-left"></div>
    </div>
    <div class="slider__next">
      <div class="icon-arrow-right"></div>
    </div>
  </div>
</div> */
