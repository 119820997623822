//--------------------Intersection observer

const animateTarget = document.querySelectorAll(".animate-observe");
const options = {};

const animateTargetObserver = new IntersectionObserver(function (
  entries,
  observer
) {
  entries.forEach(function (entry) {
    if (entry.isIntersecting) {
      entry.target.classList.add("animate--reveal");
    } else {
      // entry.target.classList.remove("animate--reveal");
    }
  });
},
options);

animateTarget.forEach(function (animateTarget) {
  animateTargetObserver.observe(animateTarget);
});
